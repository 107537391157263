import PKCE from "js-pkce";

export const getAuthClient = () =>
  new PKCE({
    client_id: "console_client_id",
    redirect_uri: `${window.location.origin}/login/callback`,
    authorization_endpoint: `${import.meta.env["VITE_API_URL"]}/authorize`,
    token_endpoint: `${import.meta.env["VITE_API_URL"]}/token`,
    requested_scopes: "email",
    storage: localStorage,
  });

export * from "./lib/api";
export * from "./lib/endpoints/users";
export * from "./lib/endpoints/assemblies";
export * from "./lib/endpoints/investments";
export * from "./lib/endpoints/investment-documents";
export * from "./lib/endpoints/consents";
export * from "./lib/endpoints/organization-persons";
export * from "./lib/endpoints/organization-members";
export * from "./lib/endpoints/organizations";
export * from "./lib/endpoints/projects";
export * from "./lib/endpoints/user-tax-exemptions";
export * from "./lib/endpoints/wma-customers";
export * from "./lib/endpoints/wma-investments";
export * from "./lib/endpoints/wma-profiles";
export * from "./lib/endpoints/project-holder-profiles";
export * from "./lib/endpoints/webflow-collection";
export * from "./lib/endpoints/organization-shareholders";
export * from "./lib/endpoints/reportings";
export * from "./lib/endpoints/wallet";
