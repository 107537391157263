import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

type Reason = "unauthenticated" | "incomplete-profile" | null;

export function useRestrictedAccess() {
  const { isQualifiedOrWma, isConnected } = useUserBusiness();
  const { pathname } = useLocation();

  const reason = useMemo<Reason>(() => {
    if (!isConnected) return "unauthenticated";
    if (!isQualifiedOrWma) return "incomplete-profile";
    return null;
  }, [isConnected, isQualifiedOrWma]);

  const authUrl = useMemo<string>(() => getAuthClient().authorizeUrl(), []);

  const login = useCallback(() => {
    localStorage.setItem("target-url", pathname);
    window.location.replace(authUrl);
  }, [authUrl, pathname]);

  const signup = useCallback(() => {
    localStorage.setItem("target-url", pathname);

    const authorizedUrl = new URL(authUrl);
    authorizedUrl.searchParams.append("register", "1");

    window.location.replace(authorizedUrl.toString());
  }, [authUrl, pathname]);

  return {
    restricted: reason !== null,
    reason,
    login,
    signup,
  };
}
