import {
  Organization,
  PaymentIntent,
  PmsTransaction,
  WalletMoneyOutTransferRequestBody,
} from "@tudigo-monorepo/core-tudigo-api-models";

import {
  ContentType,
  type Request,
  type RequestParams,
  type TudigoResponse,
} from "../api";

export const walletTransactionsFields =
  "id,sender_organization,receiver_organization,bank_account,type,method,status,total_amount_in_cents,execution_date,reference,investment";

export const walletEndpoints = (request: Request) => ({
  /**
   * Récupère les transactions liées à un portefeuille d'organisation.
   *
   * @name getOrganizationWalletTransactions
   * @request GET:/api/v1/organizations/{organizationId}/wallet/transactions
   */
  getOrganizationWalletTransactions: (
    organizationId: string,
    query?: { [key: string]: unknown },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<PmsTransaction[]>, void>({
      path: `/api/v1/organizations/${organizationId}/wallet/transactions`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  requestMoneyInByCard: (
    organizationId: Organization["id"],
    body: {
      amountInCents: number;
    },
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<PaymentIntent>, void>({
      path: `/api/v1/organizations/${organizationId}/wallet/money-in-by-card-request`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  requestVibanCreation: (
    organizationId: Organization["id"],
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Organization>, void>({
      path: `/api/v1/organizations/${organizationId}/wallet/create-viban-request`,
      method: "POST",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  requestMoneyOut: (
    organizationId: Organization["id"],
    body: WalletMoneyOutTransferRequestBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<PmsTransaction>, void>({
      path: `/api/v1/organizations/${organizationId}/wallet/money-out-transfer-request`,
      method: "POST",
      query: query,
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
