import type { PropsWithChildren } from "react";
import algoliasearch from "algoliasearch/lite";
import { history } from "instantsearch.js/es/lib/routers";
import { InstantSearch, type InstantSearchProps } from "react-instantsearch";
import { useLocation } from "react-router-dom";

import { getAlgoliaIndexName } from "..";

const appId = import.meta.env["VITE_ALGOLIA_APP_ID"];
const apiKey = import.meta.env["VITE_ALGOLIA_API_KEY"];
const algoliaSearchClient = algoliasearch(appId, apiKey);

export const SearchProvider = (
  props: PropsWithChildren<Omit<InstantSearchProps, "searchClient">>,
) => {
  const indexName = getAlgoliaIndexName();
  const location = useLocation();

  return (
    <InstantSearch
      {...props}
      key={location.pathname}
      searchClient={algoliaSearchClient}
      indexName={indexName}
      routing={{
        router: history({
          cleanUrlOnDispose: false,

          createURL: ({ qsModule, location, routeState }) => {
            // current search params
            const indexState = routeState[indexName] || {};
            const { origin, pathname, hash, search } = location;
            // grab current query string and convert to object
            const queryParameters = qsModule.parse(search.slice(1)) || {};

            // if there is an active search
            if (Object.keys(indexState).length) {
              // merge the search params with the current query params
              Object.assign(queryParameters, routeState);
            } else {
              // remove the search params
              delete queryParameters[indexName];
            }

            let queryString = qsModule.stringify(queryParameters);

            if (queryString.length) {
              queryString = `?${queryString}`;
            }

            return `${origin}${pathname}${queryString}${hash}`;
          },
        }),
      }}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      {props.children}
    </InstantSearch>
  );
};
