import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { lazyWithReload } from "@tudigo-monorepo/core-tudigo-utils";
import { TudigoFullPageLoading } from "@tudigo-monorepo/web-tudigo-components";
import { ProtectedRoute } from "@tudigo-monorepo/web-tudigo-security";

const WmaRoutes = lazyWithReload(() =>
  import("./spaces/wma-routes").then((module) => ({
    default: module.WmaRoutes,
  })),
);
const ProjectHolderRoutes = lazyWithReload(() =>
  import("./spaces/project-holder-routes").then((module) => ({
    default: module.ProjectHolderRoutes,
  })),
);
const ClubLeaderRoutes = lazyWithReload(() =>
  import("./spaces/club-leader-routes").then((module) => ({
    default: module.ClubLeaderRoutes,
  })),
);
const InvestorRoutes = lazyWithReload(() =>
  import("./spaces/investor-routes").then((module) => ({
    default: module.InvestorRoutes,
  })),
);
const LogoutPage = lazyWithReload(() =>
  import("@tudigo-monorepo/web-feature-login").then((module) => ({
    default: module.LogoutPage,
  })),
);
const QualificationRoute = lazyWithReload(() =>
  import("@tudigo-monorepo/web-feature-qualification").then((module) => ({
    default: module.QualificationRoute,
  })),
);
const RetryPage = lazyWithReload(() =>
  import("@tudigo-monorepo/web-tudigo-security").then((module) => ({
    default: module.RetryPage,
  })),
);

export const LazyConnectedRoutes = () => (
  <Suspense fallback={<TudigoFullPageLoading />}>
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/wma/*" element={<WmaRoutes />} />
        <Route path="/project-holder/*" element={<ProjectHolderRoutes />} />
        <Route path="/club-leader/*" element={<ClubLeaderRoutes />} />
        <Route path="/my-account?/*" element={<InvestorRoutes />} />
        <Route>
          <Route path="/qualification/*" element={<QualificationRoute />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/retry" element={<RetryPage />} />
          <Route path="*" element={<Navigate to="/dispatch" />} />
        </Route>
      </Route>
    </Routes>
  </Suspense>
);
