import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type PropsWithChildren,
} from "react";
import { useSearchParams } from "react-router-dom";

type ComparatorMode = "selection" | "comparison";

type ComparatorSelection<T> = Array<T>;

type ComparatorState<T> = {
  mode: ComparatorMode;
  selection: ComparatorSelection<T>;
  amount: number;
};

type ComparatorConfig<T> = {
  minSelection: number;
  maxSelection: number;
  defaultSimulatorAmount: number;
  compareFn: (a: T, b: T) => boolean;
  itemQueryParamFn: (item: T) => string;
};

type ComparatorContextValue<T> = {
  config: ComparatorConfig<T>;
  mode: ComparatorMode | null;
  selection: ComparatorSelection<T>;
  selectionCount: number;
  canSelect: boolean;
  simulatorAmount: number | null;

  setSimulatorAmount: (amount: number | null) => void;
  setMode: (mode: ComparatorMode | null) => void;
  setSelection: (selection: ComparatorSelection<T>) => void;
  addItem: (item: T) => void;
  removeItem: (item: T) => void;
  isSelected: (item: T) => boolean;

  getSearchParamsValue: (key: keyof ComparatorState<T>) => Array<string> | null;
  setSearchParamsValue: (
    key: keyof ComparatorState<T>,
    value: Array<string | number>,
  ) => void;
};

const ComparatorContext = createContext<ComparatorContextValue<any> | null>(
  null,
);

function useComparator<T>() {
  const context = useContext(ComparatorContext);

  if (context === null) {
    throw new Error("useComparator must be used within a ComparatorProvider");
  }

  return context as ComparatorContextValue<T>;
}

const DEFAULT_MIN_SELECTION = 2;
const DEFAULT_AMOUNT = 1000;

type ComparatorProviderProps<T> = Omit<
  ComparatorConfig<T>,
  "minSelection" | "defaulSimulatortAmount"
> & {
  minSelection?: number;
  defaultSimulatorAmount?: number;
};

function ComparatorProvider<T>({
  children,
  ...configProps
}: PropsWithChildren<ComparatorProviderProps<T>>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const config = useMemo(
    () =>
      ({
        ...configProps,
        minSelection: configProps.minSelection ?? DEFAULT_MIN_SELECTION,
        defaultSimulatorAmount:
          configProps.defaultSimulatorAmount ?? DEFAULT_AMOUNT,
      }) satisfies ComparatorConfig<T>,
    [configProps],
  );

  const getSearchParamsValue: ComparatorContextValue<T>["getSearchParamsValue"] =
    useCallback(
      (key) => {
        return searchParams.get(`comparator-${key}`)?.split(",") ?? null;
      },
      [searchParams],
    );

  const setSearchParamsValue: ComparatorContextValue<T>["setSearchParamsValue"] =
    useCallback(
      (key, value) => {
        if (!value.length) {
          searchParams.delete(`comparator-${key}`);
        } else {
          searchParams.set(`comparator-${key}`, value.join(","));
        }
        setSearchParams(searchParams.toString());
      },
      [searchParams, setSearchParams],
    );

  const [mode, setMode] = useState<ComparatorMode | null>(
    () => (getSearchParamsValue("mode")?.[0] as ComparatorMode | "") || null,
  );
  const [selection, setSelection] = useState<ComparatorSelection<T>>([]);

  const addItem = useCallback(
    (item: T) => {
      const selectionParams = getSearchParamsValue("selection");

      if (selectionParams) {
        setSearchParamsValue("selection", [
          ...new Set([...selectionParams, config.itemQueryParamFn(item)]),
        ]);
      } else {
        setSearchParamsValue("selection", [config.itemQueryParamFn(item)]);
      }

      setSelection((prevSelection) => [...prevSelection, item]);
    },
    [
      selection,
      getSearchParamsValue,
      setSearchParamsValue,
      setSelection,
      config,
    ],
  );

  const removeItem = useCallback(
    (item: T) => {
      const selectionParams = getSearchParamsValue("selection");

      if (selectionParams) {
        setSearchParamsValue(
          "selection",
          selectionParams.filter(
            (selectedKey) => config.itemQueryParamFn(item) !== selectedKey,
          ),
        );
      }

      setSelection((prevSelection) =>
        prevSelection.filter(
          (selectedItem) => !config.compareFn(selectedItem, item),
        ),
      );
    },
    [getSearchParamsValue, setSearchParamsValue, setSelection, config],
  );

  const isSelected = useCallback(
    (item: T) =>
      selection.some((selectedItem) => config.compareFn(item, selectedItem)),
    [selection, config],
  );

  const simulatorAmount = getSearchParamsValue("amount")?.[0] ?? null;

  const setSimulatorAmount = (amount: number | null) => {
    setSearchParamsValue("amount", amount ? [amount] : []);
  };

  const context = useMemo<ComparatorContextValue<T>>(
    () => ({
      config,
      mode,
      selection,
      selectionCount: selection.length,
      canSelect: mode === "selection" && selection.length < config.maxSelection,
      simulatorAmount: simulatorAmount ? Number(simulatorAmount) : null,
      setSimulatorAmount,
      setMode: (mode) => {
        setSearchParamsValue("mode", mode ? [mode] : []);
        setMode(mode);
      },
      setSelection: (selection) => {
        setSearchParamsValue(
          "selection",
          selection.map(config.itemQueryParamFn),
        );
        setSelection(selection);
      },
      addItem,
      removeItem,
      isSelected,
      getSearchParamsValue,
      setSearchParamsValue,
    }),
    [
      config,
      mode,
      selection,
      simulatorAmount,
      setSimulatorAmount,
      setMode,
      setSelection,
      addItem,
      removeItem,
      isSelected,
      getSearchParamsValue,
      setSearchParamsValue,
    ],
  );

  return (
    <ComparatorContext.Provider value={context}>
      {children}
    </ComparatorContext.Provider>
  );
}

export {
  type ComparatorMode,
  type ComparatorSelection,
  type ComparatorContextValue,
  useComparator,
  ComparatorProvider,
};
