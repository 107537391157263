import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const bannerVariants = cva(
  `
  relative w-full rounded-md border p-6
  font-roobert text-neutral-900
  `,
  {
    variants: {
      variant: {
        default: "bg-white border-neutral-300",
        error: "bg-error-100 border-error-200",
        success: "bg-success-100 border-success-200",
        info: "bg-primary-50 border-primary-200",
        warning: "bg-warning-100 border-warning-500",
        loading: "bg-waiting-100 border-waiting-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const Banner = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof bannerVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(bannerVariants({ variant }), className)}
    {...props}
  />
));

const BannerTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      "body400-medium mb-2 flex items-center gap-2 text-balance leading-none tracking-tight text-black",
      className,
    )}
    {...props}
  />
));

const BannerDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("body200-regular [&_p]:leading-relaxed", className)}
    {...props}
  />
));

export { Banner, BannerTitle, BannerDescription };
