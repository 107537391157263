import {
  forwardRef,
  useMemo,
  type ComponentProps,
  type ComponentPropsWithoutRef,
} from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { ImageIcon, TimerIcon } from "lucide-react";
import { Link, LinkProps, type To } from "react-router-dom";

import { type Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { getResizedImage } from "@tudigo-monorepo/core-tudigo-utils";
import { useProjectBusiness } from "@tudigo-monorepo/web-feature-projects";

import { ProjectTotalAmountCollected } from "../../../ui/project-total-amount-collected/project-total-amount-collected";
import { BadgeRebrand } from "../../ui/badge-rebrand/badge-rebrand";
import { ClubProfilePicture } from "../club-profile-picture/club-profile-picture";
import { useProjectCardBadges } from "./hooks/use-project-card-badges";
import { useProjectCardStatusBadge } from "./hooks/use-project-card-status-badge";
import { ProjectCardProvider, useProjectCard } from "./project-card.context";

interface CProjectCardProps extends ComponentProps<"div"> {
  project: Project;
  to?: To;
  target?: LinkProps["target"];
}

const CProjectCard = forwardRef<HTMLDivElement, CProjectCardProps>(
  (
    { children, className: classNameProp, project, to, target, ...props },
    ref,
  ) => {
    const clickable = !!(to || props.onClick);

    const className = useMemo(
      () =>
        cn(
          "font-roobert flex w-full flex-col overflow-hidden rounded-lg bg-white",
          clickable &&
            "hover:bg-black-800 cursor-pointer duration-300 ease-in-out hover:shadow-[0_2px_10px_0px_rgba(13,13,12,0.05)]",
          classNameProp,
        ),
      [classNameProp, clickable],
    );

    return (
      <ProjectCardProvider project={project}>
        <div ref={ref} className="contents" {...props}>
          {to ? (
            <Link to={to} target={target} className={className}>
              {children}
            </Link>
          ) : (
            <div className={className}>{children}</div>
          )}
        </div>
      </ProjectCardProvider>
    );
  },
);

const CProjectCardCover = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn("relative", className)} {...props}>
      {children}
    </div>
  );
});

const CProjectCardImage = forwardRef<
  HTMLImageElement,
  ComponentPropsWithoutRef<"img">
>(({ className, alt = "Project cover", loading = "lazy", ...props }, ref) => {
  const { project } = useProjectCard();

  if (!project?.content?.cover) {
    return null;
  }

  const resizedImageUrl =
    getResizedImage(project.content.cover, {
      requestedHeight: 240,
      requestedWidth: 360,
      requestedMode: "resized",
    }).url ?? undefined;

  return (
    <img
      ref={ref}
      alt={alt}
      loading={loading}
      src={resizedImageUrl}
      className={cn(
        "pointer-events-none h-[240px] w-full rounded-tl-lg rounded-tr-lg object-cover",
        className,
      )}
      {...props}
    />
  );
});

const CProjectCardImageFallback = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => {
  const { project } = useProjectCard();
  const projectContent = project?.content;

  if (projectContent?.cover) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn(
        "flex h-[240px] items-center justify-center rounded-tl-lg rounded-tr-lg bg-gray-100",
        className,
      )}
      aria-label={projectContent?.name || "Project image"}
      {...props}
    >
      <ImageIcon className="text-dark-4 h-10 w-10" />
    </div>
  );
});

const getProjectCardStatusBadgeVariants = cva(
  "absolute bottom-3 left-3 w-auto rounded h-7 px-2 flex items-center gap-2 justify-center font-medium text-xs",
  {
    variants: {
      variant: {
        default: "bg-white",
        progress: "bg-black-800 text-black-900",
        new: "bg-accent-super-light-rebrand text-primary-rebrand",
        coming: "bg-secondary-super-light text-[#8E6D00]",
        "accent-super-light":
          "bg-accent-super-light border-transparent text-accent",
        success: "text-success bg-success-light",
        "success-light": "bg-success-light border-transparent text-success",
        warning: "bg-warning-super-light border-transparent text-warning",
        waiting: "bg-waiting-super-light border-transparent text-waiting",
        destructive: "bg-error-light text-error",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

type ProjectCardStatusBadgeVariant =
  | "default"
  | "progress"
  | "new"
  | "coming"
  | "accent-super-light"
  | "success"
  | "success-light"
  | "warning"
  | "waiting"
  | "destructive";

const CProjectCardStatusBadge = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof BadgeRebrand> &
    VariantProps<typeof getProjectCardStatusBadgeVariants>
>(({ children, className, variant: variantProp, ...props }, ref) => {
  const { project } = useProjectCard();
  const { isLastHours, isLastDays } = useProjectBusiness(project);
  const { label, variant } = useProjectCardStatusBadge(project);

  if (!label) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn(getProjectCardStatusBadgeVariants({ variant }), className)}
      {...props}
    >
      {(isLastHours || isLastDays) && (
        <TimerIcon
          className={`h-4 w-4 ${
            isLastHours ? "text-destructive" : "text-warning"
          }`}
        />
      )}
      {label}
    </div>
  );
});

const CProjectCardClubAvatar = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const { project } = useProjectCard();
  const club = project.club;

  if (!club) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn("absolute bottom-3 right-5", className)}
      {...props}
    >
      <ClubProfilePicture club={club} size="XS" className="rounded-tl-lg" />
    </div>
  );
});

const CProjectCardContent = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "border-black-600 flex h-full flex-col justify-between rounded-b-lg border bg-transparent px-6 py-6",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});

const CProjectCardHeader = forwardRef<
  HTMLHeadElement,
  ComponentPropsWithoutRef<"header">
>(({ children, className, ...props }, ref) => {
  return (
    <header
      ref={ref}
      className={cn("mb-2 flex items-center gap-2", className)}
      {...props}
    >
      {children}
    </header>
  );
});

const CProjectCardAvatar = forwardRef<
  HTMLImageElement,
  ComponentPropsWithoutRef<"img">
>(({ className, alt = "Project logo", loading = "lazy", ...props }, ref) => {
  const { project } = useProjectCard();

  if (!project?.content?.logo) {
    return null;
  }

  const resizedImageUrl =
    getResizedImage(project.content.logo ?? "", {
      requestedHeight: 30,
      requestedWidth: 30,
      requestedMode: "cropped",
    }).url ?? undefined;

  return (
    <img
      ref={ref}
      alt={alt}
      loading={loading}
      src={resizedImageUrl}
      className={cn(
        "pointer-events-none h-[30px] w-[30px] rounded-lg p-0.5",
        className,
      )}
      {...props}
    />
  );
});

const CProjectCardAvatarFallback = forwardRef<
  HTMLImageElement,
  ComponentPropsWithoutRef<"img">
>(({ className, alt = "Project logo", loading = "lazy", ...props }, ref) => {
  const { project } = useProjectCard();

  if (project?.content?.logo) {
    return null;
  }

  if (!project?.content?.cover) {
    return (
      <div
        aria-label={project?.content?.name || "Project logo"}
        className="flex h-[30px] w-[30px] items-center justify-center rounded-lg bg-gray-100 p-0.5"
      >
        <ImageIcon className="text-dark-4 h-4 w-4" />
      </div>
    );
  }

  const resizedImageUrl =
    getResizedImage(project.content.cover, {
      requestedHeight: 30,
      requestedWidth: 30,
      requestedMode: "cropped",
    }).url ?? undefined;

  return (
    <img
      ref={ref}
      alt={alt}
      loading={loading}
      src={resizedImageUrl}
      className={cn(
        "pointer-events-none h-[30px] w-[30px] rounded-lg p-0.5",
        className,
      )}
      {...props}
    />
  );
});

const CProjectCardLabel = forwardRef<
  HTMLHeadingElement,
  ComponentPropsWithoutRef<"h2">
>(({ children, className, ...props }, ref) => {
  const { project } = useProjectCard();

  return (
    <h2
      ref={ref}
      className={cn(
        "text-black-primary max-w-full truncate text-2xl font-semibold leading-7",
        className,
      )}
      {...props}
    >
      {children ?? project?.content?.name}
    </h2>
  );
});

const CProjectCardBadges = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div"> & {
    badgeClassName?: string;
  }
>(({ badgeClassName, children, className, ...props }, ref) => {
  const { project } = useProjectCard();
  const { mechanic, tag, totalExtraCount } = useProjectCardBadges(project);

  return (
    <div
      ref={ref}
      className={cn(
        "mb-4 flex flex-wrap items-center gap-2 font-medium",
        className,
      )}
      {...props}
    >
      <BadgeRebrand variant="primary" className={badgeClassName}>
        {mechanic}
      </BadgeRebrand>
      {tag && (
        <BadgeRebrand variant="secondary" className={badgeClassName}>
          {tag.name}
        </BadgeRebrand>
      )}
      {totalExtraCount > 0 && (
        <BadgeRebrand variant="secondary" className={badgeClassName}>
          +{totalExtraCount}
        </BadgeRebrand>
      )}
    </div>
  );
});

const CProjectCardDescription = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<"p">
>(({ children, className, ...props }, ref) => {
  const { project } = useProjectCard();

  return (
    <p
      ref={ref}
      className={cn(
        "text-black-900 mb-4 line-clamp-3 h-[54px] text-sm leading-tight",
        className,
      )}
      {...props}
    >
      {project?.content?.shortDescription}
    </p>
  );
});

const CProjectCardFooter = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"footer">
>(({ children, className, ...props }, ref) => {
  return (
    <footer
      ref={ref}
      className={cn("flex items-center justify-between", className)}
      {...props}
    >
      {children}
    </footer>
  );
});

const CProjectCardAmount = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const { project } = useProjectCard();
  const { totalAmountCollectedInCents, fundraisingProgressMinGoal } = project;

  if (project.marketingSettings?.gaugeVisibility !== "visible") {
    return null;
  }

  return (
    <div ref={ref} className="w-fit" {...props}>
      <ProjectTotalAmountCollected
        className={cn(
          "font-roobert text-sm font-[650] leading-[14px]",
          fundraisingProgressMinGoal >= 100
            ? "text-success"
            : "text-black-primary",
          className,
        )}
        amount={totalAmountCollectedInCents}
      >
        <span> investis</span>
      </ProjectTotalAmountCollected>
    </div>
  );
});

const CProjectCardPercentage = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const { project } = useProjectCard();
  const { fundraisingProgressMinGoal } = project;

  if (project.marketingSettings?.gaugeVisibility !== "visible") {
    return null;
  }

  return (
    <div ref={ref} className="flex items-center gap-2" {...props}>
      <BadgeRebrand
        variant={fundraisingProgressMinGoal >= 100 ? "success" : "tertiary"}
        className={cn("leading-none", className)}
      >
        {`${fundraisingProgressMinGoal}% de l'objectif`}
      </BadgeRebrand>
    </div>
  );
});

export {
  type CProjectCardProps,
  type ProjectCardStatusBadgeVariant,
  CProjectCard,
  CProjectCardCover,
  CProjectCardImageFallback,
  CProjectCardImage,
  CProjectCardStatusBadge,
  CProjectCardClubAvatar,
  CProjectCardContent,
  CProjectCardHeader,
  CProjectCardAvatar,
  CProjectCardAvatarFallback,
  CProjectCardLabel,
  CProjectCardBadges,
  CProjectCardDescription,
  CProjectCardFooter,
  CProjectCardAmount,
  CProjectCardPercentage,
};
