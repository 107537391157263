import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Lemonway(props: IconElementsProps) {
  const { className, width, height, size = "S" } = props;

  return (
    <svg
      viewBox="0 0 314 74"
      x="0px"
      y="0px"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
    >
      <path
        d="M93.3398 27.2008H98.9702V42.5754H108.764V47.5046H93.3398V27.2008Z"
        fill="#585B79"
      ></path>
      <path
        d="M116.738 27.2008H133.042V32.0127H122.309V35.0641H132.045V39.5239H122.309V42.6927H133.159V47.5046H116.738V27.2008Z"
        fill="#585B79"
      ></path>
      <path
        d="M142.376 27.2008H148.358L153.167 35.0641L158.035 27.2008H163.959V47.5046H158.387V35.827L153.167 43.8077H153.05L147.889 35.8857V47.5046H142.376V27.2008Z"
        fill="#585B79"
      ></path>
      <path
        d="M188.872 37.411V37.3524C188.872 34.4183 186.761 31.895 183.653 31.895C180.603 31.895 178.55 34.4183 178.55 37.2937V37.3524C178.55 40.2865 180.662 42.8098 183.711 42.8098C186.82 42.8098 188.872 40.3451 188.872 37.411ZM172.744 37.411V37.3524C172.744 31.5429 177.436 26.7896 183.711 26.7896C189.987 26.7896 194.62 31.4842 194.62 37.2937V37.3524C194.62 43.1619 189.928 47.9151 183.653 47.9151C177.377 47.9151 172.744 43.2205 172.744 37.411Z"
        fill="#585B79"
      ></path>
      <path
        d="M203.422 27.2008H208.642L217.028 37.9395V27.2008H222.541V47.5046H217.615L208.994 36.3551V47.5046H203.422V27.2008Z"
        fill="#585B79"
      ></path>
      <path
        d="M230.461 27.1998H236.384L239.903 38.9361L243.833 27.1411H248.642L252.571 38.9361L256.149 27.1998H261.955L255.152 47.621H250.284L246.179 35.9434L242.132 47.621H237.206L230.461 27.1998Z"
        fill="#585B79"
      ></path>
      <path
        d="M279.962 39.5229L277.675 33.7721L275.387 39.5229H279.962ZM275.035 27.0824H280.431L289.052 47.5036H283.012L281.546 43.8653H273.745L272.279 47.5036H266.355L275.035 27.0824Z"
        fill="#585B79"
      ></path>
      <path
        d="M300.071 39.9347L292.388 27.2008H298.781L302.944 34.5947L307.167 27.2008H313.443L305.76 39.8174V47.5046H300.071V39.9347Z"
        fill="#585B79"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.557617 37C0.557617 16.8135 16.862 0.5 37.037 0.5C57.212 0.5 73.5161 16.8722 73.5161 37C73.5161 57.1865 57.1534 73.5 37.037 73.5C16.9207 73.5 0.557617 57.1278 0.557617 37ZM60.4924 38.8194C60.6097 38.4673 60.727 38.1739 60.8443 37.8805C61.1718 37.1158 61.4992 36.5545 61.6848 36.4804L61.7238 36.4721C61.7116 36.4721 61.6986 36.4749 61.6848 36.4804C57.0157 37.4788 54.095 39.1178 52.3989 40.9319C51.2846 38.526 49.4663 36.3547 47.0617 34.8877C46.2993 34.3596 45.5371 33.8901 44.716 33.4207C41.4903 31.6015 37.7367 30.4866 34.3351 30.0172L32.0477 29.313C28.2356 28.022 24.7167 26.2028 21.7256 23.3861C21.2059 22.9775 20.7333 22.5689 20.2779 22.1752C20.0138 21.9469 19.7556 21.7237 19.4973 21.5083C17.4446 19.7478 16.1543 18.3395 16.1543 18.3395C14.2189 42.4576 21.667 53.7832 30.5228 57.7736C31.1676 58.1255 31.8712 58.3601 32.5746 58.5947L32.5757 58.5951C35.2736 59.4166 38.03 59.6514 40.9624 59.534C45.1264 59.358 48.176 57.4214 50.7566 54.37L50.874 54.2527C49.3491 49.9689 44.4813 41.1667 29.6433 35.8266C29.6433 35.8266 46.8859 37.5871 52.3402 51.7293V51.788C53.5718 50.8491 57.0906 47.9737 57.3251 42.6337C57.3251 42.6337 58.4395 48.3845 54.3927 51.5533C56.6214 51.8467 58.7329 50.3797 59.3193 48.1498C59.4364 47.857 59.4949 47.5643 59.5534 47.2716L59.5538 47.2695C59.7884 46.0372 59.7885 44.7462 59.6125 43.5726V42.7511C59.6125 41.4014 59.9059 40.0517 60.4924 38.8194Z"
        fill="#57ED7D"
      ></path>
    </svg>
  );
}
