import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type ReactNode,
} from "react";
import { ListChecksIcon, Trash2Icon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog/dialog";
import { CheckboxInput } from "../../../ui/form/inputs/checkbox-input/checkbox-input";
import { RebrandBadge } from "../../../ui/rebrand-badge";
import { ButtonRebrand } from "../../ui/button/button-rebrand";
import { RestrictedAccess } from "../restricted-access/restricted-access";
import { useRestrictedAccess } from "../restricted-access/use-restricted-access";
import { useComparator } from "./comparator.context";

const ComparatorModeTrigger = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand> & {
    iconClassName?: string;
  }
>(({ children, className, iconClassName, ...props }, ref) => {
  const { mode, selectionCount, config, setMode, setSimulatorAmount } =
    useComparator();
  const { restricted } = useRestrictedAccess();

  const handleOpenModal = () => {
    setMode("comparison");
    setSimulatorAmount(config.defaultSimulatorAmount);
  };

  const updateMode = useCallback(() => {
    if (restricted) return;
    if (mode === null) setMode("selection");
    if (mode === "selection") handleOpenModal();
  }, [restricted, mode, setMode, handleOpenModal]);

  return (
    <RestrictedAccess openAsDialog className="lg:w-[802px]">
      <ButtonRebrand
        ref={ref}
        variant="secondary"
        onClick={updateMode}
        disabled={mode === "selection" && selectionCount < config.minSelection}
        className={cn(
          "font-roobert flex items-center text-sm font-medium transition-all",
          mode === "selection" &&
            "bg-accent-super-light-rebrand ring-accent text-accent ring-[3px]",
          className,
        )}
        {...props}
      >
        <span>
          Comparer
          {mode === "selection" && ` ${selectionCount}/${config.maxSelection}`}
        </span>
        <ListChecksIcon
          className={cn("ml-2.5 min-h-5 min-w-5", iconClassName)}
        />
      </ButtonRebrand>
    </RestrictedAccess>
  );
});

const ComparatorClear = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand>
>(({ children, className, ...props }, ref) => {
  const { mode, setMode, setSelection } = useComparator();

  const handleClear = () => {
    setMode(null);
    setSelection([]);
  };

  if (mode !== "selection") return null;

  return (
    <ButtonRebrand
      ref={ref}
      variant="ghost"
      onClick={handleClear}
      className={cn(
        "font-roobert text-sm font-medium text-black underline",
        className,
      )}
      {...props}
    >
      Annuler
    </ButtonRebrand>
  );
});

const ComparatorBanner = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const { mode } = useComparator();

  if (mode === null) return null;

  return (
    <div
      ref={ref}
      className={cn(
        "z-comparator-banner flex flex-col border-t border-[#DFE2E8] bg-neutral-100",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});

const ComparatorBannerHeader = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"header">
>(({ children, className, ...props }, ref) => (
  <header
    ref={ref}
    className={cn(
      "flex flex-col gap-4 px-4 py-4 sm:flex-row sm:justify-between",
      className,
    )}
    {...props}
  >
    {children}
  </header>
));

const ComparatorBannerTitle = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const { selectionCount } = useComparator();

  return (
    <div
      ref={ref}
      className={cn(
        "flex w-full items-center justify-between gap-2.5 lg:w-fit",
        className,
      )}
      {...props}
    >
      <p className="font-roobert text-lg font-semibold text-black">
        {children}
      </p>
      <RebrandBadge className="font-roobert h-6 w-6 whitespace-nowrap rounded-full border-neutral-200 bg-white text-xs font-medium text-neutral-900">
        {selectionCount}
      </RebrandBadge>
    </div>
  );
});

const ComparatorBannerDescription = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<"p">
>(({ children, className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("font-roobert px-4 text-sm text-neutral-900", className)}
    {...props}
  >
    {children}
  </p>
));

const ComparatorSelectableItem = <T,>({
  children,
  className,
  item,
  ...props
}: ComponentPropsWithoutRef<"div"> & { item: T }) => {
  const {
    mode,
    canSelect,
    config,
    addItem,
    removeItem,
    isSelected,
    getSearchParamsValue,
  } = useComparator<T>();

  const handleSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        addItem(item);
      } else {
        removeItem(item);
      }
    },
    [item, addItem, removeItem],
  );

  useEffect(() => {
    const selectionParams = getSearchParamsValue("selection");
    if (selectionParams?.length) {
      const itemParam = selectionParams.find(
        (param) => param === config.itemQueryParamFn(item),
      );
      if (itemParam && !isSelected(item)) {
        addItem(item);
      }
    }
  }, [
    item,
    getSearchParamsValue,
    config.itemQueryParamFn,
    isSelected,
    addItem,
  ]);

  if (mode !== "selection") return children;

  return (
    <div
      data-comparator-selectable-item=""
      className={cn(
        "z-comparator-selectable-item relative rounded-lg ring-[6px] ring-transparent transition-all",
        isSelected(item) && "ring-accent",
        !isSelected(item) && canSelect && "hover:ring-accent-light",
        className,
      )}
      {...props}
    >
      <ButtonRebrand
        variant="ghost"
        onClick={() => handleSelect(!isSelected(item))}
        className="h-full w-full p-0"
        disabled={!isSelected(item) && !canSelect}
      >
        {children}
      </ButtonRebrand>
      <CheckboxInput
        disabled={!isSelected(item) && !canSelect}
        checked={isSelected(item)}
        onCheckedChange={handleSelect}
        className="z-comparator-selectable-item-checkbox absolute right-4 top-4 h-6 w-6 bg-white"
      />
    </div>
  );
};

const ComparatorSelectionList = <T,>({
  children,
  className,
}: {
  children: (item: T, index: number, arr: T[]) => ReactNode;
  className?: string;
}) => {
  const { mode, selection } = useComparator<T>();

  if (mode === null) return null;

  if (className) {
    return (
      <div className={className}>
        {selection.map((item, index, arr) => (
          <Fragment key={index}>{children(item, index, arr)}</Fragment>
        ))}
      </div>
    );
  }

  return selection.map((item, index, arr) => (
    <Fragment key={index}>{children(item, index, arr)}</Fragment>
  ));
};

const ComparatorSelectedItem = <T,>({
  children,
  className,
  item,
  removeButtonAlignOffset = "middle",
  removeButtonClassName,
  disableRemove = false,
  ...props
}: ComponentPropsWithoutRef<"div"> & {
  item: T;
  removeButtonAlignOffset?: "top" | "middle" | "bottom";
  removeButtonClassName?: string;
  disableRemove?: boolean;
}) => {
  const { selectionCount, removeItem } = useComparator<T>();

  return (
    <div
      data-comparator-selected-item=""
      className={cn("relative flex flex-col", className)}
      {...props}
    >
      {children}
      {selectionCount > 2 && !disableRemove && (
        <ButtonRebrand
          onClick={() => removeItem(item)}
          variant="secondary"
          className={cn(
            "absolute right-4 h-12 w-12 rounded-full",
            removeButtonAlignOffset === "top" && "top-0",
            removeButtonAlignOffset === "middle" && "top-[calc(50%-24px)]",
            removeButtonAlignOffset === "bottom" && "bottom-0",
            removeButtonClassName,
          )}
        >
          <Trash2Icon className="text-black-900 h-5 w-5" />
        </ButtonRebrand>
      )}
    </div>
  );
};

const ComparatorModal = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Dialog> & {
  className?: string;
}) => {
  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const { mode, setMode, setSimulatorAmount } = useComparator();

  const handleCloseModal = () => {
    setMode("selection");
    setSimulatorAmount(null);
  };

  if (mode !== "comparison") return null;

  return (
    <Dialog
      {...props}
      open={mode === "comparison"}
      onOpenChange={handleCloseModal}
    >
      <DialogContent
        data-comparator-modal-content=""
        ref={modalContentRef}
        hasCloseButton={false}
        className={cn(
          "flex h-dvh w-screen flex-col overflow-x-hidden p-0 sm:h-[calc(100dvh-45px)] sm:max-w-[1390px] sm:rounded-xl",
          className,
        )}
      >
        {children}
        <DialogFooter />
      </DialogContent>
    </Dialog>
  );
};

const ComparatorModalHeader = ({
  children,
  className,
}: ComponentPropsWithoutRef<typeof DialogHeader>) => (
  <DialogHeader
    className={cn(
      "relative w-full space-y-0 px-4 pt-4 sm:px-8 sm:pt-8 lg:flex-row lg:justify-between",
      className,
    )}
  >
    <DialogClose className="absolute right-6 top-4 sm:right-8 sm:top-8" />
    {children}
  </DialogHeader>
);

const ComparatorModalTitle = ({
  children,
  className,
}: ComponentPropsWithoutRef<typeof DialogHeader>) => {
  const { selectionCount } = useComparator();

  return (
    <DialogTitle
      className={cn(
        "flex items-center gap-4 text-balance text-left",
        className,
      )}
    >
      {children}
      <RebrandBadge className="font-roobert h-6 w-6 whitespace-nowrap rounded-full border-neutral-200 bg-neutral-100 text-xs font-medium text-neutral-900">
        {selectionCount}
      </RebrandBadge>
    </DialogTitle>
  );
};

const ComparatorSimulatorAmount = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex w-full flex-col gap-6 border border-[#DFE2E8] bg-neutral-100 p-4 sm:rounded-lg sm:p-6",
      className,
    )}
    {...props}
  >
    {children}
  </div>
));

const ComparatorSimulatorAmountHeader = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"header">
>(({ children, className, ...props }, ref) => (
  <header
    ref={ref}
    className={cn(
      "flex w-full flex-col gap-6 lg:flex-row lg:justify-between",
      className,
    )}
    {...props}
  >
    {children}
  </header>
));

const ComparatorSimulatorAmountTitle = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<"p">
>(({ children, className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "font-roobert text-center text-lg font-semibold leading-[25px] sm:text-left",
      className,
    )}
    {...props}
  >
    {children}
  </p>
));

const ComparatorSimulatorAmountDescription = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<"p">
>(({ children, className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "font-roobert text-center text-sm leading-[19.6px] text-neutral-900 sm:text-left",
      className,
    )}
    {...props}
  >
    {children}
  </p>
));

export {
  // triggers
  ComparatorModeTrigger,
  // ComparatorSelectionTrigger,
  // ComparatorModalTrigger,
  ComparatorClear,
  // banner
  ComparatorBanner,
  ComparatorBannerHeader,
  ComparatorBannerTitle,
  ComparatorBannerDescription,
  // selection
  ComparatorSelectableItem,
  ComparatorSelectionList,
  ComparatorSelectedItem,
  // modal
  ComparatorModal,
  ComparatorModalHeader,
  ComparatorModalTitle,
  // simulator amount
  ComparatorSimulatorAmount,
  ComparatorSimulatorAmountHeader,
  ComparatorSimulatorAmountTitle,
  ComparatorSimulatorAmountDescription,
};
