import React from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { ButtonRebrand, Icon } from "@tudigo-monorepo/web-tudigo-components";

const SITE_URL = import.meta.env["VITE_WEBSITE_URL"];
const scheduleMeetingUrl = new URL(`${SITE_URL}/conseillers-investissement`)
  .href;

const advantagesList: string[] = [
  "Analyse de vos objectifs",
  "Stratégie sur mesure",
  "Simulations",
  "Avantages fiscaux",
];

const AdvantageItem = ({ label }: { label: string }) => (
  <li className="flex gap-4">
    <div className="bg-accent-super-light flex h-6 w-6 items-center justify-center rounded-full">
      <Icon
        name="Checked"
        primaryColor={themeColors["primary-rebrand"]}
        aria-label="Checked icon"
      />
    </div>
    <span className="text-black-primary text-sm">{label}</span>
  </li>
);

const AppointmentCardNew = ({ className }: { className?: string }) => {
  const openScheduleMeetingPage = () => {
    window.open(scheduleMeetingUrl, "_blank");
  };

  return (
    <div
      className={cn(
        "appointment-card-container font-roobert flex min-h-[312px] flex-col-reverse gap-6 md:flex-row",
        className,
      )}
    >
      <div className="appointment-card-left md:w-1/2">
        <div className="gap flex flex-col gap-2">
          <h2 className="text-black-primary text-2xl font-bold">
            Faites-vous accompagner
          </h2>
          <p className="text-black-900 text-sm">
            Nos chargés d’investissement participatif sont là pour vous aider
            gratuitement.
          </p>
        </div>

        <ul className="mb-6 mt-6 flex flex-col gap-1.5">
          {advantagesList.map((label, index) => (
            <AdvantageItem key={index} label={label} />
          ))}
        </ul>

        <ButtonRebrand
          variant="primary-rebrand"
          onClick={openScheduleMeetingPage}
          className="md: mb-2 flex gap-2"
        >
          <Icon name="Helpdesk" primaryColor="white" size="XS" />
          Prendre rendez-vous
        </ButtonRebrand>

        <div className="flex flex-wrap gap-2 md:gap-1">
          <div className="flex items-center gap-0.5">
            <span className="text-xs">Recommandé par</span>
            <Icon name="StarTrustpilot" primaryColor={themeColors["success"]} />
            <span className="font-roboto text-xs font-bold">Trustpilot:</span>
          </div>
          <a
            href="https://www.trustpilot.com/review/tudigo.co"
            target="_blank"
            rel="noreferrer"
            aria-label="Voir les avis Trustpilot"
            className="flex gap-3"
          >
            <div className="flex items-center gap-0.5">
              <span className="text-success text-xs font-bold">
                Excellent 4.7
              </span>
              <span className="text-xs">(1793 avis)</span>
            </div>
            <div className="flex gap-[2px]">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="bg-success flex h-5 w-5 items-center justify-center rounded p-[3px] pb-[4px]"
                  >
                    <Icon
                      name="StarTrustpilot"
                      primaryColor={themeColors["white"]}
                    />
                  </div>
                ))}
            </div>
          </a>
        </div>
      </div>

      <div className="appointment-card-right min-h-[240px] rounded-lg bg-red-500/10 bg-[url('https://tudigo-prod.s3.eu-west-3.amazonaws.com/static/website/img_banner_contact.jpeg')] bg-cover bg-center md:w-1/2"></div>
    </div>
  );
};

export { AppointmentCardNew };
