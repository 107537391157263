import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type PropsWithChildren,
} from "react";
import { ChevronDownIcon, InfoIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../ui/popover/popover";
import { ButtonRebrand } from "../../ui/button/button-rebrand";
import { ComparatorGrid, ComparatorGridColumn } from "./comparator-grid";
import { useComparator } from "./comparator.context";

type ComparatorTableContextValue = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ComparatorTableContext =
  createContext<ComparatorTableContextValue | null>(null);

function useComparatorTable() {
  const context = useContext(ComparatorTableContext);

  if (context === null) {
    throw new Error(
      "useComparatorTable must be used within a ComparatorTableProvider",
    );
  }

  return context;
}

const DEFAULT_ROW_HEIGHT = 42;

type ComparatorTableProps = {
  defaultOpen?: boolean;
};

function ComparatorTableProvider({
  children,
  defaultOpen = false,
}: PropsWithChildren<ComparatorTableProps>) {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const contextValue = useMemo<ComparatorTableContextValue>(
    () => ({ open, setOpen }),
    [open, setOpen],
  );

  return (
    <ComparatorTableContext.Provider value={contextValue}>
      {children}
    </ComparatorTableContext.Provider>
  );
}

const ComparatorTable = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div"> & ComparatorTableProps
>(({ children, className, defaultOpen = false, ...props }, ref) => {
  const { selectionCount } = useComparator();

  return (
    <ComparatorTableProvider defaultOpen={defaultOpen}>
      <div
        ref={ref}
        data-comparator-table=""
        className={cn(
          "w-full min-w-max lg:min-w-full",
          selectionCount > 2 && "lg:min-w-max",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    </ComparatorTableProvider>
  );
});

const ComparatorTableTrigger = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand>
>(({ children, className, ...props }, ref) => {
  const { open, setOpen } = useComparatorTable();

  const toggleOpen = useCallback(() => setOpen(!open), [open, setOpen]);

  return (
    <ButtonRebrand
      ref={ref}
      data-comparator-table-trigger=""
      onClick={toggleOpen}
      variant="ghost"
      className={cn(
        "bg-accent-super-light-rebrand relative z-20 min-h-10 w-full rounded-none",
        open ? "border-b-0" : "border-y border-neutral-300",
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          "fixed left-0 h-10 min-w-[200px] lg:left-[71px]",
          "flex items-center justify-start px-4",
          "text-accent font-roobert rounded-none bg-inherit text-xs font-semibold",
          open ? "-mt-px border-b-0" : "border-y border-neutral-300",
        )}
      >
        {children}
        <ChevronDownIcon
          className={cn(
            "ml-2 h-4 w-4 shrink-0 transition-transform duration-300",
            open && "rotate-180",
          )}
        />
      </div>
    </ButtonRebrand>
  );
});

const ComparatorTableContent = forwardRef<
  ElementRef<"ul">,
  ComponentPropsWithoutRef<"ul">
>(({ children, className, ...props }, ref) => {
  const contentRef = useRef<HTMLUListElement>(null);
  const { open } = useComparatorTable();

  useImperativeHandle(ref, () => contentRef.current as HTMLUListElement);

  return (
    <ul
      ref={contentRef}
      data-comparator-table-content=""
      className={cn(
        "ease h-0 w-full opacity-0 transition-opacity duration-300",
        open && "h-auto opacity-100",
        className,
      )}
      {...props}
    >
      {children}
    </ul>
  );
});

const ComparatorTableRow = forwardRef<
  ElementRef<"li">,
  ComponentPropsWithoutRef<"li">
>(({ children, className, ...props }, ref) => {
  const id = useId();

  return (
    <li
      ref={ref}
      id={id}
      data-comparator-table-row=""
      className={cn(
        "relative min-h-10",
        "odd:bg-black-400 even:bg-white",
        "even:border-y even:border-neutral-300",
        "[&>div>[data-comparator-table-row-label]]:odd:bg-black-400",
        "[&>div>[data-comparator-table-row-label]]:even:bg-white",
        "[&>div>[data-comparator-table-row-label]]:even:-mt-px",
        "[&>div>[data-comparator-table-row-label]]:even:border-y",
        "[&>div>[data-comparator-table-row-label]]:even:border-neutral-300",
        className,
      )}
      {...props}
    >
      <ComparatorGrid>{children}</ComparatorGrid>
    </li>
  );
});

const ComparatorTableRowLabel = forwardRef<
  ElementRef<"span">,
  ComponentPropsWithoutRef<"span">
>(({ children, className, ...props }, ref) => {
  const { open } = useComparatorTable();

  const labelRef = useRef<HTMLSpanElement>(null);
  useImperativeHandle(ref, () => labelRef.current as HTMLSpanElement);

  const [rowHeight, setRowHeight] = useState<number>(DEFAULT_ROW_HEIGHT);

  useEffect(() => {
    if (open && rowHeight === DEFAULT_ROW_HEIGHT) {
      const height =
        labelRef.current?.closest("[data-comparator-table-row]")
          ?.clientHeight ?? 0;
      const BORDER_Y = 2;

      setRowHeight(height + BORDER_Y);
    }
  }, [open, rowHeight, setRowHeight]);

  return (
    <span
      ref={labelRef}
      data-comparator-table-row-label=""
      style={{
        height:
          open && rowHeight !== DEFAULT_ROW_HEIGHT
            ? `${rowHeight}px`
            : DEFAULT_ROW_HEIGHT,
      }}
      className={cn(
        "fixed -translate-x-4 lg:-translate-x-[200px]",
        "min-h-10 w-[172px] px-4 py-1.5 sm:py-3 lg:w-[200px]",
        "flex items-center gap-x-2",
        "font-roobert border-r border-neutral-300 bg-inherit text-xs text-neutral-900",
        !open && "hidden",
        rowHeight > DEFAULT_ROW_HEIGHT && "items-start",
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
});

const ComparatorTableRowValue = forwardRef<
  ElementRef<"span">,
  ComponentPropsWithoutRef<"span">
>(({ children, className }, ref) => {
  const { selectionCount, config } = useComparator();

  return (
    <ComparatorGridColumn
      className={cn(
        "ml-[166px] lg:ml-0 lg:w-auto first-of-type:lg:ml-0",
        selectionCount === config.minSelection &&
          "ml-[146px] w-[calc(100vw-192px)] first-of-type:ml-[166px]",
      )}
    >
      <span
        ref={ref}
        data-comparator-table-row-value=""
        className={cn(
          "inline-block h-full w-full",
          "py-3 lg:px-4",
          "font-roobert text-black-primary text-xs font-semibold",
          className,
        )}
      >
        {children}
      </span>
    </ComparatorGridColumn>
  );
});

const ComparatorTableTooltip = forwardRef<
  ElementRef<typeof PopoverContent>,
  ComponentPropsWithoutRef<typeof PopoverContent>
>(({ children, className, ...props }, ref) => (
  <Popover>
    <PopoverTrigger>
      <InfoIcon className="h-4 w-4 text-neutral-900" />
    </PopoverTrigger>
    <PopoverContent
      ref={ref}
      side="top"
      className={cn(
        "font-montserrat block min-w-[200px] text-balance p-3 text-center text-xs",
        className,
      )}
      {...props}
    >
      {children}
    </PopoverContent>
  </Popover>
));

export {
  ComparatorTable,
  ComparatorTableTrigger,
  ComparatorTableContent,
  ComparatorTableRow,
  ComparatorTableRowLabel,
  ComparatorTableRowValue,
  ComparatorTableTooltip,
};
