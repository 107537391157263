import { forwardRef, useMemo, type ComponentPropsWithoutRef } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useComparatorScroller } from "./comparator-scroller/store/hooks/use-comparator-scroller";
import { useComparator } from "./comparator.context";

const ComparatorGrid = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => {
  const { selectionCount, mode } = useComparator();
  const {
    state: { scrollableItemWidth },
  } = useComparatorScroller();

  const gridAutoColumns = useMemo<string | undefined>(
    () =>
      mode === "selection" || (mode === "comparison" && selectionCount > 2)
        ? `${scrollableItemWidth}px`
        : undefined,
    [mode, selectionCount, scrollableItemWidth],
  );

  return (
    <div
      ref={ref}
      style={{ gridAutoColumns }}
      className={cn(
        "grid w-full grid-flow-col",
        "gap-x-4 lg:gap-x-8",
        "mr-4 px-4 sm:mr-0 sm:px-0",
        mode === "comparison" && selectionCount === 2 && "lg:auto-cols-fr",
        className,
      )}
      {...props}
    />
  );
});

const ComparatorGridColumn = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("col-span-1 snap-center", className)}
    {...props}
  />
));

export { ComparatorGrid, ComparatorGridColumn };
