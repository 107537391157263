import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { lazyWithReload } from "@tudigo-monorepo/core-tudigo-utils";
import { TudigoFullPageLoading } from "@tudigo-monorepo/web-tudigo-components";
import { AppLayout } from "@tudigo-monorepo/web-tudigo-layout";

const AuthCallbackPage = lazyWithReload(() =>
  import("@tudigo-monorepo/web-feature-login").then((module) => ({
    default: module.AuthCallbackPage,
  })),
);
const ConnectPage = lazyWithReload(() =>
  import("@tudigo-monorepo/web-feature-login").then((module) => ({
    default: module.ConnectPage,
  })),
);
const LoginPage = lazyWithReload(() =>
  import("@tudigo-monorepo/web-feature-login").then((module) => ({
    default: module.LoginPage,
  })),
);
const SignupPage = lazyWithReload(() =>
  import("@tudigo-monorepo/web-feature-login").then((module) => ({
    default: module.SignupPage,
  })),
);
const ProjectRoutesWebApp = lazyWithReload(() =>
  import("@tudigo-monorepo/web-feature-projects").then((module) => ({
    default: module.ProjectRoutesWebApp,
  })),
);
const ClubsV2ClubPageRouteWebapp = lazyWithReload(() =>
  import("@tudigo-monorepo/web/feature-clubs-v2").then((module) => ({
    default: module.ClubsV2ClubPageRouteWebapp,
  })),
);
const RetryPage = lazyWithReload(() =>
  import("@tudigo-monorepo/web-tudigo-security").then((module) => ({
    default: module.RetryPage,
  })),
);

export const LazyNotConnectedRoutes = () => (
  <Suspense fallback={<TudigoFullPageLoading />}>
    <Routes>
      <Route>
        <Route path="/connect" element={<ConnectPage />} />
        <Route path="/login" element={<AppLayout />}>
          <Route index element={<LoginPage />} />
          <Route path="/login/callback" element={<AuthCallbackPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/retry" element={<RetryPage />} />
        <Route path="/clubs-v2/*" element={<ClubsV2ClubPageRouteWebapp />} />
        <Route path="/projects?/*" element={<ProjectRoutesWebApp />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  </Suspense>
);
