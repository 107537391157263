import { createContext, type RefObject, type UIEvent } from "react";

type ComparatorScrollerState = {
  scrollerElementRef: RefObject<HTMLDivElement>;
  scrollLeft: number;
  scrolledItemsCount: number;
  scrolling: boolean;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  maxScrollWidth: number | undefined;
  scrollableItemWidth: number;
};

type ComparatorScrollerActions = {
  scrollPrev: () => Promise<void>;
  scrollNext: () => Promise<void>;
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
};

interface UseComparatorScrollerValue extends ComparatorScrollerActions {
  state: ComparatorScrollerState;
}

const ComparatorScrollerContext =
  createContext<UseComparatorScrollerValue | null>(null);

export { type ComparatorScrollerState, ComparatorScrollerContext };
