import { useMemo } from "react";

import {
  InternalKycStatus,
  UserRole,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useImpersonateId } from "@tudigo-monorepo/web-tudigo-auth-store";

import { useSessionStore } from "../../session-store";
import { getInvestorAverageInvestmentAmountCapacityInCents } from "../utils/get-investor-average-investment-amount-capacity-in-cents";

export function useUserBusiness() {
  const user = useSessionStore((state) => state.user);
  const role = useSessionStore((state) => state.sessionRole);
  const impersonateId = useImpersonateId();

  const isIndividualOrganizationKycApproved =
    user?.individualOrganization?.internalKycStatus ===
    InternalKycStatus.APPROVED;

  const isQualified = useMemo(() => {
    if (!user) {
      return false;
    }

    return !user?.investorProfile?.isQualificationExpired;
  }, [user]);

  const isWma = useMemo(() => {
    return user?.appRoles?.includes(UserRole.WMA);
  }, [user]);

  const isAdmin = useMemo(() => {
    return user?.appRoles?.includes(UserRole.ADMIN);
  }, [user]);

  const projectOwnerProjects = user?.projectHolderProfile?.projects;

  const clubLeaderClubs = user?.circlesOwnedAsCircleLeader;

  const averageInvestmentAmountCapacityInCents = useMemo(
    () => getInvestorAverageInvestmentAmountCapacityInCents(user, isQualified),
    [user, isQualified],
  );

  return {
    isConnected: !!user,
    isAdmin,
    isQualified,
    isWma,
    isQualifiedOrWma: isQualified || isWma,
    user,
    sessionRole: role,
    isIndividualOrganizationKycApproved,
    projectOwnerProjects,
    clubLeaderClubs,
    userIdOrMe: user?.id || "me",
    averageInvestmentAmountCapacityInCents,
    isImpersonate: !!impersonateId,
  };
}
