import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
} from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { z } from "zod";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

import { CurrencyInputConnected } from "../../../ui/form/inputs/currency-input/currency-input-connected";
import { ButtonRebrand } from "../../ui/button/button-rebrand";
import { useComparator } from "./comparator.context";

const formSchema = z
  .object({
    minAmount: z.number(),
    simulatorAmount: z.number(),
  })
  .superRefine((formData, formContext) => {
    if (formData.simulatorAmount < formData.minAmount) {
      formContext.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["simulatorAmount"],
        message: `Le montant minimum est de ${formatCurrency(
          formData.minAmount * 100,
        )}`,
      });
    }
  });

const ComparatorSimulatorAmountForm = ({
  children,
}: PropsWithChildren<{
  min?: number;
}>) => {
  const { config, simulatorAmount, setSimulatorAmount } = useComparator();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      minAmount: config.defaultSimulatorAmount,
      simulatorAmount: simulatorAmount ?? config.defaultSimulatorAmount,
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    setSimulatorAmount(data.simulatorAmount);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-6 sm:px-10"
      >
        {children}
      </form>
    </FormProvider>
  );
};

const ComparatorSimulatorAmountFormField = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const form = useFormContext();

  const handleValueChange = (value: string | undefined) => {
    const digitOnlyValue = value?.replace(/\[^\D]/, "");
    form.setValue("simulatorAmount", Number(digitOnlyValue));
  };

  return (
    <div
      ref={ref}
      className={cn(
        "relative flex min-h-[38px] w-full items-start focus-within:gap-1 md:w-auto md:max-w-[596px] md:justify-end",
        className,
      )}
      {...props}
    >
      <CurrencyInputConnected
        name="simulatorAmount"
        onValueChange={handleValueChange}
        className="w-1/2"
        inputClassName="rounded-r-none border-[#DFE2E8]"
      />
      <ButtonRebrand
        type="submit"
        variant="primary-rebrand"
        className="font-roobert w-1/2 rounded-l-none"
      >
        Relancer la simulation
      </ButtonRebrand>
    </div>
  );
});

export { ComparatorSimulatorAmountForm, ComparatorSimulatorAmountFormField };
