import React from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

import { useNotifications } from "../../../hooks/use-notification";
import { NotificationButton } from "../../button/notification-button";
import { NotificationList } from "../../list/notification-list";

export function NotificationMobileDialogNav() {
  const {
    notifications,
    unreadCount,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    markAllNotificationsAsRead,
  } = useNotifications();

  const handleOpenChange = (open: boolean) => {
    if (!open && unreadCount > 0) {
      markAllNotificationsAsRead();
    }
  };

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <div className="w-full">
          <NotificationButton
            variant="secondary"
            className="w-full"
            unreadNotificationsCount={unreadCount}
          />
        </div>
      </DialogTrigger>

      <DialogContent hasCloseButton={false} className="h-dvh w-screen p-0">
        <DialogHeader className="border-light-1 flex h-[60px] flex-row items-center gap-2 border-b px-4">
          <h2 className="text-lg font-bold">Notifications</h2>
          {unreadCount > 0 && (
            <div className="text-waiting bg-secondary-super-light rounded-full px-2.5 py-2 text-xs">
              {unreadCount}
            </div>
          )}
          <DialogClose className="ml-auto" />
        </DialogHeader>

        <div className="w-full overflow-y-auto p-4">
          <NotificationList
            notifications={notifications}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
