import * as React from "react";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const questionToggleVariants = {
  variant: {
    default: `
      bg-white border border-neutral-200
      hover:bg-neutral-50 hover:border-neutral-300
      data-[state=on]:bg-primary-50 data-[state=on]:border-primary-200 data-[state=on]:text-primary-500
      disabled:bg-neutral-100 disabled:border-neutral-500
      disabled:data-[state=on]:opacity-50
      text-left
    `,
    success: `bg-success-100 border border-success-200`,
    error: `bg-error-100 border border-error-200`,
  },
  size: {
    default: "px-6 py-4",
    mobile: "p-4",
  },
};

const questionToggleCVA = cva("rounded font-roobert focus-keyboard", {
  variants: questionToggleVariants,
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

const QuestionToggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof questionToggleCVA>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(questionToggleCVA({ variant, size, className }))}
    {...props}
  />
));

export { QuestionToggle, questionToggleVariants, questionToggleCVA };
