export const QUERY_KEYS = {
  ANALYSTS: "analysts",
  ASSEMBLIES: "assemblies",
  ASSEMBLY_PARTICIPANTS: "assembly_participants",
  BANKS: "banks",
  CIRCLES_MEMBERS: "circles_members",
  CIRCLE_INVESTMENTS: "circle_investments",
  CIRCLE_SUBSCRIPTION_PERIODS: "circle_subscription_periods",
  CLUB_MEMBER: "club_member",
  CLUBS: "clubs",
  COMMENTS: "comments",
  CONFIGURATION: "configuration",
  DEBTOR_REPAYMENT_CREDITORS_REPAYMENTS:
    "debtor_repayment_creditors_repayments",
  DEBTOR_REPAYMENTS_PERIODS: "debtor_repayments_periods",
  FORM: "forms",
  INDUSTRIES: "industries",
  INVESTMENT_FEED: "investment_feed",
  INVESTMENT_STATUS_CHANGES: "investment_status_changes",
  INVESTMENTS: "investments",
  INVESTOR_DOCUMENTS: "investor_documents",
  INVESTOR_PROFILE: "investorProfile",
  MENTORINGS: "mentorings",
  MY_CLUB_MEMBERS: "my-club-members",
  NOTIFICATIONS: "notifications",
  WALLET_TRANSACTIONS: "wallet_transactions",
  ORGANIZATION: "organization",
  ORGANIZATION_EQUITY_SAVING_PLANS: "organization_equity_saving_plans",
  ORGANIZATION_MEMBERS: "organization_members",
  ORGANIZATION_SHAREHOLDERS: "organization_shareholders",
  ORGANIZATIONS: "organizations",
  PROJECT_HOLDER_INVESTORS: "project_holder_investors",
  PROJECT_HOLDER_PROFILES: "project_holder_profiles",
  PROJECT_INVESTORS: "project_investors",
  PROJECT_NEWS: "project-news",
  PROJECTS: "projects",
  REPORTINGS: "reportings",
  SIMULATOR: "simulator",
  SPECIALITIES: "specialities",
  SUBSCRIPTION_PERIODS: "subscription_periods",
  TAX_EXEMPTIONS: "tax_exemptions",
  TERRITORIES: "territories",
  USERS: "users",
  USER_ORGANIZATIONS: "user_organizations",
  USER_TODOS: "user_todos",
  WEBFLOW_COLLECTIONS: "webflow_collections",
  WMA_CUSTOMERS: "wma_customers",
  WMA_INVESTMENTS: "wma_investments",
  WMA_PROFILES: "wma_profiles",
};
