import React from "react";

import {
  Answers,
  AnswerSetter,
  Form,
  FormPage,
  FormSection,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { DynamicFormActionBinding } from "../dynamic-form";
import { FormInputPlugin } from "../input-plugins/form-input-plugin";

export type DynamicFormContextType = {
  form: Form | null;
  sections: FormSection[];
  pages: FormPage[];
  currentPageIndex: number;
  currentPage: FormPage;
  goToPage: (page: number) => void;
  getCurrentSection: () => FormSection | undefined;
  answers: Answers;
  setAnswer: AnswerSetter;
  resetForm: () => void;
  summaryPages?: FormPage[];
  actionsBinding?: DynamicFormActionBinding;
  sectionKey?: string | null;
  inputPlugins?: FormInputPlugin<any, any>[];
  submit: (answers: Answers) => void;
  actionsState: Record<string, string>;
  setActionState: (actionName: string, state: string) => void;
  isFormDone: boolean;
};

export const DynamicFormContext = React.createContext<DynamicFormContextType>({
  form: null,
  sections: [],
  pages: [],
  summaryPages: undefined,
  currentPageIndex: 0,
  answers: {},
  currentPage: {
    index: 0,
    sectionKey: "",
    inputs: [],
    id: "",
    title: "",
    rendering: [],
    actions: [],
  },
  isFormDone: false,
  submit: () => {},
  // eslint-disable-next-line unused-imports/no-unused-vars
  goToPage: (page: number) => {},
  getCurrentSection: () => undefined,
  setAnswer: () => {
    return {};
  },
  resetForm: () => {},
  actionsBinding: {},
  inputPlugins: [],
  actionsState: {},
  setActionState: () => {},
});

export const useDynamicFormContext = () => React.useContext(DynamicFormContext);
