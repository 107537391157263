import { useContext } from "react";

import { ComparatorScrollerContext } from "../comparator-scroller.context";

export function useComparatorScroller() {
  const context = useContext(ComparatorScrollerContext);

  if (context === null) {
    throw new Error(
      "useComparatorScroller must be used within a ComparatorScrollerProvider",
    );
  }

  return context;
}
