import {
  isSharesProject,
  type BondsProject,
  type Project,
  type SharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Tooltip } from "@tudigo-monorepo/web-tudigo-components";

import { Icon } from "../../old/icons/icon";
import { BadgeRebrand } from "../ui/badge-rebrand/badge-rebrand";

const Setting = (props: {
  label: string;
  value: string | number | string[] | number[];
  tooltipDescription?: string;
}) => {
  const { label, value } = props;
  const { isQualifiedOrWma } = useUserBusiness();

  return (
    <li className="flex min-h-5 flex-wrap items-center gap-2">
      <p className="text-black-900 text-xs">{label}</p>
      <Tooltip placement="top" description={props.tooltipDescription}>
        <Icon name="Info" size="XS" primaryColor={themeColors["black-900"]} />
      </Tooltip>
      {!isQualifiedOrWma ? (
        <Icon
          name="Cadenas"
          size="XS"
          primaryColor={themeColors["black-primary"]}
        />
      ) : (
        <p className="text-black-primary text-xs font-semibold">{value}</p>
      )}
    </li>
  );
};

const MaxInPercentSetting = (props: {
  project: SharesProject;
  isLimitedAccess?: boolean;
}) => {
  const { t } = useTranslation();
  const potentialReturnInYears =
    props.project?.sharesMechanicSettings?.potentialReturnPeriodInMonth / 12;
  const displayReturnInYears =
    potentialReturnInYears % 1 === 0
      ? Math.round(potentialReturnInYears)
      : potentialReturnInYears.toFixed(1);
  const targetedYield = props.project?.sharesMechanicSettings?.targetedYield;

  if (!targetedYield) {
    return null;
  }

  return (
    <Setting
      label={t("component.project_card.list.item.targeted_exit")}
      value={`x${
        targetedYield.maxInPercent / 100
      } à ${displayReturnInYears} ans`}
      tooltipDescription="Objectif d’après notre scénario défini par le management de la société"
    />
  );
};

const ProjectCardTaxExemptionMechanics = (props: {
  project: SharesProject;
}) => {
  const { t } = useTranslation();

  const { taxExemptionMechanics } = props.project;

  if (!taxExemptionMechanics || taxExemptionMechanics.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-wrap items-center gap-2">
      <div className="flex flex-wrap gap-1">
        <div className="flex items-center gap-1">
          <p className="text-black-900 text-xs">
            {t("component.project_card.list.item.tax_exemption")}
          </p>
          <Tooltip
            placement="top"
            description={t(
              "component.project_card.list.item.tax_exemption.info",
            )}
          >
            <Icon
              name="Info"
              size="XS"
              primaryColor={themeColors["black-900"]}
            />
          </Tooltip>
        </div>

        {taxExemptionMechanics?.map((tax, index) => {
          if (tax === "JEIR" || tax === "JEIC") {
            const taxPercentage = tax === "JEIR" ? 50 : 30;

            return (
              <BadgeRebrand key={index} variant="primary">
                {t(`component.project_card.list.item.tax_exemption.${tax}`)}{" "}
                {taxPercentage}%
              </BadgeRebrand>
            );
          }

          if (tax === "IR") {
            const taxPercentage = 18;

            return (
              <BadgeRebrand key={index} variant="tertiary">
                {t(`component.project_card.list.item.tax_exemption.${tax}`)}{" "}
                {taxPercentage}%
              </BadgeRebrand>
            );
          }

          return (
            <BadgeRebrand key={index} variant="tertiary">
              {t(`component.project_card.list.item.tax_exemption.${tax}`)}
            </BadgeRebrand>
          );
        })}
      </div>
    </div>
  );
};

const ProjectCardSharesMechanicSettings = (props: {
  project: SharesProject;
}) => {
  const { project } = props;
  const { sharesMechanicSettings } = project;

  const { t } = useTranslation();

  if (!sharesMechanicSettings) {
    return null;
  }

  const { targetedYield } = sharesMechanicSettings;

  return (
    <>
      {project?.status !== "prefinished" && project?.status !== "finished" && (
        <>
          {!!project?.riskInPercent && (
            <Setting
              label={t("component.project_card.list.item.risk")}
              value={`${project.riskInPercent / 10} /10`}
              tooltipDescription="Le potentiel de rendement a pour corolaire un risque de perte totale ou partielle du capital investit. L’analyse financière proposée sur la page projet et dans la note d’investissement détaille le calcul effectué par nos analystes pour chaque levée."
            />
          )}
          {!!targetedYield?.maxInPercent && (
            <MaxInPercentSetting project={project} />
          )}
          {project.impactScoreInPercent !== null &&
            project.impactScoreInPercent > 0 && (
              <Setting
                label={t("component.project_card.list.item.impact")}
                value={`${project.impactScoreInPercent / 10} /10`}
                tooltipDescription="Note d’impact environnementale et/ou sociétal, attribuée par nos analystes pour un projet."
              />
            )}
        </>
      )}

      <ProjectCardTaxExemptionMechanics project={project} />
    </>
  );
};

const ProjectCardBondsMechanicSettings = (props: { project: BondsProject }) => {
  const { project } = props;
  const { bondsMechanicSettings } = project;

  const { t } = useTranslation();

  if (!bondsMechanicSettings) {
    return null;
  }

  const {
    isConvertible,
    nonConversionBonusNoDecimalRate,
    conversionHorizonInMonth,
    annualInterestNoDecimalRate,
    durationInMonth,
    repaymentType,
  } = bondsMechanicSettings;

  if (isConvertible) {
    return (
      <>
        <Setting
          label={t(
            "component.project_card.list.item.non_conversion_bonus_rate",
          )}
          value={`${nonConversionBonusNoDecimalRate / 100} %`}
          tooltipDescription="Réduction appliquée au prix lors de la conversion d'obligations en actions."
        />
        {typeof conversionHorizonInMonth === "number" && (
          <Setting
            label={t(
              "component.project_card.list.item.conversion_horizon_in_month",
            )}
            value={`${conversionHorizonInMonth} ${t(
              "component.project_card.list.item.months",
            )}`}
            tooltipDescription="Durée de l’investissement"
          />
        )}
        {project.impactScoreInPercent !== null &&
          project.impactScoreInPercent > 0 && (
            <Setting
              label={t("component.project_card.list.item.impact")}
              value={`${project.impactScoreInPercent / 10} /10`}
              tooltipDescription="Note d’impact environnementale et/ou sociétal, attribuée par nos analystes pour un projet."
            />
          )}
      </>
    );
  }

  return (
    <>
      {project?.status !== "prefinished" && project?.status !== "finished" && (
        <>
          {!!project.riskInPercent && (
            <Setting
              label={t("component.project_card.list.item.risk")}
              value={`${project.riskInPercent / 10} /10`}
              tooltipDescription="Le potentiel de rendement a pour corolaire un risque de perte totale ou partielle du capital investit. L’analyse financière proposée sur la page projet et dans la note d’investissement détaille le calcul effectué par nos analystes pour chaque levée."
            />
          )}

          <Setting
            label={t("component.project_card.list.item.interest_rate")}
            value={`${annualInterestNoDecimalRate / 100} %`}
            tooltipDescription="C’est le pourcentage du montant emprunté qui rémunère les fonds prêtés."
          />
        </>
      )}
      <Setting
        label={t("component.project_card.list.item.duration")}
        value={`${durationInMonth}
          ${t("component.project_card.list.item.months")}`}
        tooltipDescription="Durée de l’investissement"
      />
      <Setting
        label={t("component.project_card.list.item.repayment")}
        value={t(`repayment_type.${repaymentType}`)}
        tooltipDescription="Fréquence de remboursement pour cet investissement."
      />
      {project.impactScoreInPercent !== null &&
        project.impactScoreInPercent > 0 && (
          <Setting
            label={t("component.project_card.list.item.impact")}
            value={`${project.impactScoreInPercent / 10} /10`}
            tooltipDescription="Note d’impact environnementale et/ou sociétal, attribuée par nos analystes pour un projet."
          />
        )}
    </>
  );
};

export const ProjectMechanicSettingsCard = ({
  project,
  className,
}: {
  project: Project;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "bg-black-400 border-black-500 mb-6 flex h-auto w-full flex-col gap-2 rounded-md border px-4 py-4",
        ["draft", "scheduled", "started"].includes(project.status) &&
          "min-h-[172px]",
        ["prefinished", "finished"].includes(project.status) && "min-h-[115px]",
        className,
      )}
    >
      {isSharesProject(project) ? (
        <ProjectCardSharesMechanicSettings project={project} />
      ) : (
        <ProjectCardBondsMechanicSettings project={project} />
      )}
    </div>
  );
};
