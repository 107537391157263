import React from "react";

import { CMSBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon, OutClickBox } from "@tudigo-monorepo/web-tudigo-components";

import { useEditorContext } from "../store/editor-context";

export const EditorBlock = (blockProps: CMSBlock) => {
  const { id: blockId, type: blockType } = blockProps;

  const {
    content,
    context,
    settings,
    selectedBlockId,
    selectedBlockContent,
    addBlock,
    setSelectedBlockId,
    setSettings,
    setSelectedPlugin,
    getPluginFromBlockType,
    deleteBlockById,
  } = useEditorContext();

  const { hoveredBlockId, pluginInputsHovered, previewMode, editMode } =
    settings;

  const blockRef = React.useRef<HTMLDivElement>(null);
  const isSelected = blockId === selectedBlockId;

  const selectBlock = () => {
    if (previewMode) return;
    setSelectedBlockId(blockId);
    setSelectedPlugin(getPluginFromBlockType(blockType));
  };

  const unselectBlock = () => {
    if (hoveredBlockId === null && !pluginInputsHovered) {
      setSelectedBlockId(null);
      setSelectedPlugin(null);
    }
  };

  const deleteBlock = () => {
    setSelectedBlockId(null);
    setSelectedPlugin(null);
    deleteBlockById(blockId);
  };

  const duplicateBlock = () => {
    if (selectedBlockContent) {
      addBlock(JSON.parse(JSON.stringify(selectedBlockContent)));
    }
  };

  const setHoveredBlockId = (value: string | null) => {
    setSettings((prev) => ({
      ...prev,
      hoveredBlockId: value,
    }));
  };

  React.useEffect(() => {
    if (!content.find((block) => block.id === blockId)) {
      setSelectedBlockId(null);
      setSelectedPlugin(null);
    }
  }, [content, blockId, setSelectedBlockId, setSelectedPlugin]);

  return (
    <OutClickBox onOutClick={unselectBlock}>
      <div
        ref={blockRef}
        onClick={selectBlock}
        onMouseEnter={() => setHoveredBlockId(blockId)}
        onMouseLeave={() => setHoveredBlockId(null)}
        className={cn("editor-block relative transition-colors", {
          "border-light-3 border-2 border-dashed p-2": editMode,
          "border-primary-500-light border-2 border-solid hover:border-solid":
            editMode && isSelected,
          "hover:border-accent-light": editMode && !isSelected,
          "h-[153px]": blockType === "video" && previewMode === "mobile",
          "h-[430px]": blockType === "video" && previewMode !== "mobile",
        })}
      >
        {isSelected && (
          <div
            style={{
              top: blockRef.current
                ? blockRef.current.clientTop - 40
                : undefined,
            }}
            className="border-light-3 absolute -left-px flex h-9 w-fit cursor-default items-center justify-center gap-x-2 rounded-t-lg border bg-white p-2"
          >
            <Icon name="Drag" primaryColor={themeColors["dark-2"]} />
            {getPluginFromBlockType(blockType) !== null && (
              <Icon
                name={getPluginFromBlockType(blockType)!.icon}
                primaryColor={themeColors["dark-2"]}
              />
            )}
          </div>
        )}

        {getPluginFromBlockType(blockType)?.renderingBlock(
          { ...blockProps, context },
          settings,
        )}

        {isSelected && (
          <div
            style={{
              top: blockRef.current
                ? blockRef.current.clientTop - 40
                : undefined,
            }}
            className="border-light-3 absolute -right-px flex h-9 w-fit cursor-default items-center justify-center gap-x-2 rounded-t-lg border bg-white p-2"
          >
            <button onClick={duplicateBlock}>
              <Icon
                name="Duplicate"
                className="cursor-pointer"
                primaryColor={themeColors["dark-2"]}
                width={20}
              />
            </button>
            <button onClick={deleteBlock}>
              <Icon
                name="Delete"
                className="cursor-pointer"
                primaryColor={themeColors.error}
              />
            </button>
          </div>
        )}
      </div>
    </OutClickBox>
  );
};
