import { ComponentType, lazy, LazyExoticComponent } from "react";
import { PathRouteProps, Route } from "react-router-dom";

export interface RouterRoute extends Omit<PathRouteProps, "children"> {
  children?: RouterRoute[];
}

export function renderRoutes(routes: RouterRoute[]) {
  return routes.map((route, index) => (
    <Route key={index} {...route}>
      {route.children && renderRoutes(route.children)}
    </Route>
  ));
}

export function lazyWithReload<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): LazyExoticComponent<T> {
  return lazy(() =>
    factory()
      .then((module) => ({
        default: module.default,
      }))
      .catch((error) => {
        window.alert(
          "L’application a été mise à jour. La page va se recharger.",
        );
        window.location.reload();

        const FallbackComponent: T = (() => null) as unknown as T;

        return { default: FallbackComponent };
      }),
  );
}
