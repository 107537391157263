import { ReactNode } from "react";

import {
  CommitmentProfile,
  PrivilegedSector,
  Strategy,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { Badge } from "@tudigo-monorepo/web-tudigo-components";

type InvestorProfileCardsContentProps = {
  commitmentProfile?: `${CommitmentProfile}`;
  investmentCapacity: { amount: number; currency?: string };
  strategy?: `${Strategy}`;
  privilegedSectors: `${PrivilegedSector}`[];
};

function ProfileCard({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <article
      className={cn(
        "flex flex-1 flex-col items-center  gap-2 rounded border border-neutral-300 bg-white p-5",
        className,
      )}
    >
      {children}{" "}
    </article>
  );
}

ProfileCard.Title = ({ children }: { children: ReactNode }) => (
  <h1 className="font-semibold">{children}</h1>
);

ProfileCard.Amount = ({ children }: { children: ReactNode }) => (
  <h2 className="text-primary-500 text-2xl font-bold">{children}</h2>
);

ProfileCard.Content = ({ children }: { children: ReactNode }) => (
  <p className="flex flex-1 items-center text-center text-sm text-neutral-700">
    {children}
  </p>
);

export function InvestorProfileCardsContent(
  props: InvestorProfileCardsContentProps,
) {
  const {
    commitmentProfile,
    investmentCapacity: { amount },
    privilegedSectors,
    strategy,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="!font-roobert flex flex-col gap-3">
      <ProfileCard className="bg-primary-50 border-primary-200">
        <ProfileCard.Title>
          {t(
            "qualification.summary_page.investor_profile.card.investment_capacity.title",
          )}
        </ProfileCard.Title>
        <ProfileCard.Amount>
          {t(
            "qualification.summary_page.investor_profile.card.investment_capacity.subtitle",
            {
              amount: formatCurrency(amount * 100),
            },
          )}
        </ProfileCard.Amount>
        <ProfileCard.Content>
          {t(
            "qualification.summary_page.investor_profile.card.investment_capacity.content",
          )}
        </ProfileCard.Content>
      </ProfileCard>

      <div className="flex flex-col gap-4 md:flex-row">
        <ProfileCard>
          <ProfileCard.Title>
            {t(
              "qualification.summary_page.investor_profile.card.commitment.title",
            )}
          </ProfileCard.Title>
          <Badge compact variant="accent-super-light">
            {t(
              `qualification.summary_page.investor_profile.card.commitment.${commitmentProfile}.chip.label`,
            )}
          </Badge>
          <ProfileCard.Content>
            {t(
              `qualification.summary_page.investor_profile.card.commitment.${commitmentProfile}.content`,
            )}
          </ProfileCard.Content>
        </ProfileCard>

        <ProfileCard>
          <ProfileCard.Title>
            {t(
              "qualification.summary_page.investor_profile.card.privileged_sectors.title",
            )}
          </ProfileCard.Title>
          <div className="flex gap-1">
            {privilegedSectors.map((sector) => (
              <Badge key={sector} compact variant="accent-super-light">
                {t(
                  `qualification.summary_page.investor_profile.card.privileged_sectors.${sector}.chip.label`,
                )}
              </Badge>
            ))}
          </div>

          <ProfileCard.Content>
            {t(
              "qualification.summary_page.investor_profile.card.privileged_sectors.content",
            )}
          </ProfileCard.Content>
        </ProfileCard>

        <ProfileCard>
          <h2 className="font-bold">
            {t(
              "qualification.summary_page.investor_profile.card.strategy.title",
            )}
          </h2>
          <Badge compact variant="accent-super-light">
            {t(
              `qualification.summary_page.investor_profile.card.strategy.${strategy}.chip.label`,
            )}
          </Badge>
          <ProfileCard.Content>
            {t(
              `qualification.summary_page.investor_profile.card.strategy.${strategy}.content`,
            )}
          </ProfileCard.Content>
        </ProfileCard>
      </div>
    </div>
  );
}
